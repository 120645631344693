.patient-selector {
    display: none;
    position: absolute;
    border: 1px solid var(--input-bgh);
    padding: 20px;
    padding-bottom: 0px;
    background-color: white;
    width: 100%;
}

.patient-selector.open {
    display: block;
}

.patient-selector .patient {
    border-top: 1px solid var(--input-bgh);
    padding: 5px 5px 10px 5px;
}

.patient-selector .patient:hover {
    background-color: var(--background-page);
    cursor: pointer;
}