#box-app {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 
    "main";
  width: 100%;
  height: 100vh;
}

.box-container-body {
  color: var(--color-page);
  grid-area: main;
  overflow-y: auto;
}

.box-container-body-header {
  height: 64px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
}

.box-container-body-header-actionbutton {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.box-container-body-header-firstbox {
  height: 100%;
  display: flex;
  column-gap: 0.5rem;
}

.box-container-body-header-firstbox-LogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  height: 100%;
}

.box-container-body-header-firstbox-LogoWrapper-Logo-Salutho {
  height: 28px;
}

.box-container-body-header-firstbox-LogoWrapper-Logo-Partner {
  height: 100%;
  object-fit: contain;
  padding: 0.25rem 0;
}

.box-container-body-header-lastbox-LogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  height: 100%;
}

.box-container-body-content-whiteRadius {
  padding: 1.5rem;
}

.subtitle {
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 0 20px 0;
  color: var(--text-color);
}

:root {
  --text-color: #444;
  --error-bg: lightcoral;
  --default-shadow: -1px 2px 8px -2px #999;
  --default-shadow-right: 1px 2px 8px -2px #999;
}