.MaterialBatchChart {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    flex: 0 0 calc(50% - 1rem);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

}

.MaterialBatchChart-Header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}

.MaterialBatchChart-Header-Actions {
    display: flex;
    gap: 0.5rem;
}

.MaterialBatchChart-Header-Btn > svg {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    fill: #ccc;
}

.MaterialBatchChart-Header-Btn:hover > svg {
    fill: var(--primary-color);
    transition: 0.3s ease;
}

.MaterialBatchChart-Header-Registration {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    text-align: center;
    color: #ccc;
    font-size: 1.125rem;
    font-weight: 600;
}

.MaterialBatchChart-Header-Registration > p{
    margin: 0 ;
}

.MaterialBatchChart-Info {
    font-size: 0.875rem;
}

.MaterialBatchChart-Info-Container> p > span {
    font-weight: 600;
}

.MaterialBatchChart-Info-Container> div > p > span {
    font-weight: 600;
}

.MaterialBatchChart-Info-Note-Title {
    margin-bottom: 5px;
}

.MaterialBatchChart-Info-Note {
    max-height: 90px;
    overflow-y: auto;
    margin-left: 0.5rem;
}

/* ScrollBar Style */
.MaterialBatchChart-Info-Note::-webkit-scrollbar {
    width: 6px; 
    height: 6px; 
}

.MaterialBatchChart-Info-Note::-webkit-scrollbar-track {
    background: transparent; 
}

.MaterialBatchChart-Info-Note::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px; 
}

.MaterialBatchChart-Info-Note::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.expired {
    color: #F95C5C;
}

.warning {
    color: #F8B24A;
}

.responsible {
    color: var(--primary-color);
}

.hidden {
    display: none;
}