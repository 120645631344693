.form-box {
  position: relative;
  margin-bottom: 15px;
  padding-top: 10px;
  min-height: 14px;
  z-index: 0;
  
}
.form-box label {
  color: var(--input-color);
  display: block;
  font-size: 1em;
  padding-bottom: 5px;
}

.label-input {
  position: absolute;
}

.form-box .label-input {
  /* background-image: linear-gradient(#00000000, #00000000, #00000000, var(--input-bg), #00000000); */
  background-color: var(--input-bg);
  box-shadow: 0px 0px 5px var(--input-bg);
  color: var(--text-color);
  font-weight: bold;
  display: inline-block;
  font-size: 1em;
  padding: 0px 5px;
  /* position: absolute; */
  top: 2px;
  left: 13px;
  /* z-index: 2; */
}

.form-box input,
.form-box .input,
.timepicker-container input,
.form-box textarea {
  background-color: var(--input-bg);
  border: 1px solid var(--input-bgh);
  border-radius: 4px;
  color: var(--input-color);
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 11px 15px;
  width: 100%;
  min-height: 42px;
}

.form-box textarea {
  min-height: 100px;
  min-width: 100%;
  max-width: 100%;
  border-radius: 4px;
  resize: none;
}

.form-box input:focus,
.form-box textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  border-width: 2px;
  padding: 10px 15px;
}
.form-box input[type=file]{
  padding: 5px 15px 5px 5px;
}
.form-box input[type=date],
.form-box input[type=time]{
  padding: 8px 15px;
}

.form-box > div {
  /* position: relative; */
}
.form-box .input-actions {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  right: 5px;
  top: 5px;
}

.form-box .input-actions button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  color: var(--input-color);
  cursor: pointer;

  display: flex;
  font-size: 20px;
  justify-content: center;
  min-height: 30px;
  min-width: 30px;
}

.form-box .input-actions button:hover {
  background-color: var(--secondary-bgh);
  color: var(--secondary-color);
}

.form-box .input-actions button:active {
  position: relative;
  top: 1px;
}
.form-box .input-actions button:focus {
  outline: none;
}

.form-box .textarea-actions {
  height: 100%;
  /* position: absolute; */
  right: 4px;
  top: 4px;
}

.form-box .textarea-actions button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: var(--secondary-color);
  cursor: pointer;

  display: flex;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  width: 30px;
}

.form-box .textarea-actions button:hover {
  background-color: var(--secondary-bgh);
}

.form-box .textarea-actions button:active {
  position: relative;
  top: 1px;
}
.form-box .textarea-actions button:focus {
  outline: none;
}

button.select-selected {
  background-color: var(--input-bg);
  border: 1px solid var(--input-bgh);
  border-radius: 4px;
  color: var(--input-color);
  cursor: pointer;
  display: flex;
  font-size: 1em;
  justify-content: space-between;
  padding: 11px 15px;
  width: 100%;
  margin-right: 1rem;
}
button.select-selected:after {
  content: '';
  border-bottom: 2px solid var(--input-color);
  border-left: 2px solid var(--input-color);
  height: 8px;
  width: 8px;
  transform: rotate(-50deg);
  transition: all 0.1s;
}
button.select-selected.open:after {
  margin-top: 4px;
  transform: rotate(135deg);
  transition: all 0.1s;
}
.select-filter {
  position: relative;
  margin: 6px 10px;
}
.select-filter input{
  background-color: var(--input-bg);
  border: 1px solid var(--input-bgh);
  border-radius: 4px;
  color: var(--input-color);
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 8px 15px;
  width: 100%;
  min-height: 35px;
}
.select-filter .input-actions {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  right: 4px;
  top: 0;
}
.select-filter .input-actions button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  color: var(--input-color);
  fill: var(--input-color);
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  min-height: 1.8rem;
  min-width: 1.8rem;
}
.select-filter .input-actions button:hover {
  background-color: var(--secondary-bgh);
  color: var(--secondary-color);
  fill: var(--secondary-color);
  transition: 0.3s ease;
}
.select-filter .input-actions button:active {
  position: relative;
  top: 1px;
}
.select-filter .input-actions button:focus {
  outline: none;
}

.select-all {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 10px 10px 6px 10px;
  position: relative;
}

.select-box {
  background-color: var(--background-container);
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border-radius: 4px;
  overflow: auto;
  /* position: absolute; */
  max-height: 300px;
  margin-top: 0.5rem;
  width: 100%;
  z-index: 3;
}

.select-box .select-options {
  position: relative;
}

.select-box .select-options > div {
  color: var(--input-color);
  cursor: pointer;
  padding: 0.75rem;
  position: relative;
  font-size: 0.9rem;
  border-top: 0.05px solid #eee;
}
.select-box .select-options > div:hover {
  background-color: rgb(238, 238, 238, 0.5);
  padding-left: 1rem;
}

.select-box .select-options > div.selected {
  background-color: rgb(238, 238, 238, 0.5);
  font-weight: 600;
}

.select-box .select-options > div:active {
  position: relative;
}

.select-box .select-options > div {
  align-items: center;
  display: flex;
}

.select-box.multiselect .select-options > div.selected {
  background-color: var(--input-bg);
  color: var(--input-color);
}
.select-box.multiselect .checkelement {
  border: 1px solid var(--input-bgh);
  border-radius: 4px;
  color: var(--color);
  cursor: pointer;
  display: inline-block;
  height: 18px;
  margin-right: 8px;
  position: relative;
  width: 18px;
}
.select-box.multiselect div.selected .checkelement {
  background-color: var(--primary-bg);
}
.select-box.multiselect div.selected .checkelement:after {
  content: '';
  border-bottom: 2px solid var(--secondary-color);
  border-left: 2px solid var(--secondary-color);
  height: 7px;
  left: 4px;
  margin-right: 8px;
  position: absolute;
  top: 3px;
  width: 7px;
  transform: rotate(-60deg);
}

.select-disabled {
  background-color: #ddd !important;
  border: none !important;
}

.check-box {
  align-items: center;
  color: var(--input-color);
  cursor: pointer;
  display: inline-flex;
  font-size: 1em;
  line-height: 34px;
  margin-left: 10px;
}
.check-box:first-child {
  margin-left: 0px;
}
.check-box .checkbox {
  border: 1px solid var(--input-bgh);
  border-radius: 4px;
  display: inline-block;
  height: 20px;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  width: 20px;
}
.check-box.checked .checkbox {
  background-color: var(--primary-bg);
  transition: all 0.3s;
}
.check-box.checked .checkbox:after {
  content: '';
  border-bottom: 3px solid var(--secondary-color);
  border-left: 3px solid var(--secondary-color);
  height: 10px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 15px;
  transform: rotate(-50deg);
  transition: all 0.3s;
}

.check-box .switch {
  border: 1px solid var(--input-bgh);
  border-radius: 50px;
  display: inline-block;
  height: 20px;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  width: 45px;
}
.check-box.checked .switch {
  background-color: var(--primary-bg);
  transition: all 0.3s;
}
.check-box .switch:after {
  content: '';
  background-color: var(--primary-color);
  border: 1px solid var(--input-bgh);
  border-radius: 50%;
  box-shadow: inset 0px 0px 3px 0px var(--input-bgh);
  height: 20px;
  left: 0px;
  position: absolute;
  top: -1px;
  transition: all 0.3s;
  width: 20px;
}
.check-box.checked .switch:after {
  margin-left: 25px;
  transition: all 0.3s;
}

.form-radiobutton{
  padding: 0;
}
.form-radiobutton label{
  padding: 0;
}

.form-radiobutton-row-radio-box {
  column-gap: 10px;
  display: flex;
  flex-wrap: wrap;
}

.radio-box {
  align-items: center;
  color: var(--input-color);
  cursor: pointer;
  display: inline-flex;
  line-height: 26px;
}

.radio-box-option-label {
  white-space: nowrap;
}

.radio-box .checkbox {
  border: 1px solid var(--input-bgh);
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  width: 20px;
}
.radio-box.checked .checkbox {
  background-color: var(--primary-bg);
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 2px var(--primary-color);
  transition: all 0.3s;
}

.form-box.erro input,
.form-box.erro textarea,
.form-box.erro button.select-selected {
  border:2px solid var(--error-bg);
  padding: 11px 15px;
}
.form-box.success input,
.form-box.success textarea,
.form-box.success button.select-selected {
  border: 1px solid var(--primary-bgh);
}
.form-box .campo-obrigatorio{
  color: var(--error-bg);
  padding: 0 15px;
  font-size: 11px;
  display: block;
}


.form-box .ck.ck-editor__editable_inline .font-16 {
  font-size: 16px;
}
.form-box .ck.ck-editor__editable_inline .font-18 {
  font-size: 18px;
}
.form-box .ck.ck-editor__editable_inline p {
  margin: 0 0 10px 0;
}

.form-box .time-keeper {
  /* position: absolute; */
  z-index:2;
}

.form-box .time-keeper {
  --top-selected-color: var(--primary-bg);
}

.ql-container {
  min-height: 100px;
}

.input-search {
  margin: 0;
}

.max-input {
  max-width: 350px;
}

.erro-input {
  border:2px solid var(--error-bg) !important;
  padding: 11px 15px;
}