.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 9998;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background-color: white;
    padding: 20px;
    border: 0.5px solid #ddd;
    border-radius: 2px;
    animation: modal-open 200ms ease;
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header-mb {
    margin-bottom: 0.5rem;
}

.modal-header-title {
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    color: var(--atual-color);
    font-weight: bold;
    border: 0;
}

.modal-header-btn-close {
    align-items: flex-start;
    appearance: none;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border: 0;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: block;
    float: right;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: 700;
    height: 21px;
    line-height: 21px;
    margin-top: -2px;
    opacity: 0.2;
    overflow-x: visible;
    overflow-y: visible;
    padding: 0;
    text-align: center;
    text-shadow: rgb(255, 255, 255) 0px 1px 0px;
    width: 12.2656px;
}

.modal-header-btn-close:hover {
    opacity: 0.3;
}

.modal-content {
    padding: 20px 10px;
}

.modal-buttons {
    width: 100%;
    justify-content: space-around;
    display: flex;
}

@keyframes modal-open {

    0% {
        top: 100%;
    }
    100% {
        top: 0;
    }
    
}

.modal-progress-tracker-box {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}

.modal-progress-tracker {
    width: fit-content;
    display: flex;
    margin: 0;
    counter-reset: item;
    list-style-type: none;
    padding: 0;
}

.modal-progress-tracker .modal-step:before {
    background: #AAAAAA;
    border-radius: 20px;
    color: #FFFFFF;
    content: "";
    font-size: 18px;
    line-height: 1.8em;
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 35px;
    height: 35px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    content: counter(item) "  "; 
    counter-increment: item;
}

.modal-step 
.modal-progress-tracker .modal-step {flex: 1;}

.modal-progress-tracker .modal-step .modal-step-name{
	display: inline-flex;
    margin: 0 0 0 15px;	
    background: #dddddd;
    height: 35px;
    width: 100%;
    align-items: center;
    padding: 0 30px;
    color: #777777;
    line-height: 1.2em;
    font-size: 13px;
}
.modal-progress-tracker .modal-step:last-child .modal-step-name{ border-radius: 0 20px 20px 0; width: calc(100% - 20px)}
.modal-progress-tracker .modal-step.active .modal-step-name {color: #333333; font-weight: bold; }
.modal-progress-tracker .modal-step.active:before {background: var(--primary-bg);}
.modal-progress-tracker .modal-step.completed:before {background: var(--primary-color-darker); content: "\2714"}

@media (max-width: 576px) {
    .modal {
        width: 95vw;
    }
}