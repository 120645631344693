.MaterialStockFilters-Container {
    display: flex;
    gap: 0.5rem;
}

.MaterialStockFilters-NewSelect{
padding: 0.5rem;
}

.MaterialStockFilters-Label {
    color: #aaa;
    font-weight: 500;
    padding: 0.2rem;
    margin: 0;
}

.MaterialStockFilters-Section > div {
    width: 200px;
    margin: 0;
    padding: 0;
}