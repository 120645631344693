.ListInventoryMaterials {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    margin-top: 1rem;
}

.ListInventoryMaterials-Select .SelectBox, .ListInventoryMaterials-Select .form-box, .ListInventoryMaterials-Input .form-box {
    padding-top: 4px;
}

.ListInventoryMaterials-Filters-Body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
    flex-wrap: wrap;
    max-width: 980px;

}

.ListInventoryMaterials-Status-Subtitle {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color-light-2);
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    width: min-content;
    white-space: nowrap;
}

.ListInventoryMaterials-Status-Subtitle-Error {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color-light-2);
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    width: min-content;
    border: 1px solid #f95c5c;
    color:#f95c5c;
    background-color: #fee6e6;
    white-space: nowrap;
}

.ListInventoryMaterials-HeaderRow-Action {
    text-align: left;
}

.ListInventoryMaterials-ActionRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 20px;
}

.ListInventoryMaterials-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    stroke: #bbb;
    cursor: pointer;
    height: 20px;
}

.ListInventoryMaterials-ActionRow > div > svg:hover {
    fill: var(--primary-color);
    stroke: var(--primary-color);
    transition: 0.3s ease;
}

.ListInventoryMaterials-ActionItem-ShowMore {
    padding: 0.2rem 0.4rem;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    color: #aaa;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #aaa;
    border-radius: 5px;
}

.ListInventoryMaterials-ActionItem-ShowMore:hover {
    transition: 0.3s ease;
    color: var(--primary-color);
    border-color: var(--primary-color);
    fill: var(--primary-color);
}

.ListInventoryMaterials-ActionItem-ShowMore:hover > svg{
    fill: var(--primary-color);
    transition: 0.3s ease;

}

@media (max-width: 1200px) {
    .ListInventoryMaterials-List-Presentation {
        display: none;
    }
}

@media (max-width: 1000px) {
    .ListInventoryMaterials-List-Category {
        display: none;
    }
}

@media (max-width: 800px) {
    .ListInventoryMaterials-List-Code {
        display: none;
    }
}

.ListInventoryMaterials-List-Container > div > div > table > tbody {
    overflow-y: scroll;
    height: 400px;

}

.ListInventoryMaterials-List-Container > div > div > table > tbody > tr {
    border-bottom: 1px solid #e4e4e4;
}

.ListInventoryMaterials-Loading-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;

}
