.EditInstitutionPartnersModal {
    width: 500px ;
}

.EditInstitutionPartnersModal-PersonalInfo {
    display: flex;
    flex-direction: column;
}

.EditInstitutionPartnersModal-Select .SelectBox, .EditInstitutionPartnersModal-Select .form-box, .EditInstitutionPartnersModal-Input .form-box {
    padding-top: 4px;
}

.EditInstitutionPartnersModal-Subtitle-Header {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 1rem;
    align-items: center;
}

.EditInstitutionPartnersModal-Subtitle-Header-Icon {
    -webkit-box-align: center;
    background-color: #463F3A;
    border-color: #463F3A;
    border-radius: 8px;
    border-style: none;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    fill: white;
    display: flex;
    flex-direction: column;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    text-size-adjust: 100%;
    text-underline-position: from-font;
    width: 28px;
    z-index: 2;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
}

.EditInstitutionPartnersModal-Subtitle-Header-Title {
    font-weight: 600;
}

.EditInstitutionPartners-BtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.EditInstitutionPartnersModal-Select-Filter {
    padding: 0.5rem;
}

button.select-selected {
    height: 3rem;
    display: flex;
    align-items: center;
}

@media (max-width: 576px) {
    .EditInstitutionPartnersModal {
        width: 300px ;
    }
}

