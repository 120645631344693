.ListRequestMaterials {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    margin-top: 1rem;
}

.ListRequestMaterials-NewSelect {
    padding: 0.5rem;
}

.ListRequestMaterials-Filters-Body {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1rem;
    flex-wrap: wrap;
    max-width: 1224px;
}

.ListRequestMaterials-Status-Subtitle {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color-light-2);
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    width: min-content;
}

.ListRequestMaterials-Success {
    border: 1px solid #14A44D;
    color:#14A44D;
    background-color: #e8fcf0;
}

.ListRequestMaterials-Error {
    border: 1px solid #f95c5c;
    color:#f95c5c;
    background-color: #fee6e6;
}

.ListRequestMaterials-HeaderRow-Action {
    text-align: right;
}

.ListRequestMaterials-ActionRow {
    display: flex;
    align-items: center;
    height: 20px;
    justify-content: center;
}

.ListRequestMaterials-ActionRow > div > svg {
    width: 12px;
    fill: #bbb;
    stroke: #bbb;
    cursor: pointer;
    height: 12px;
}

.ListRequestMaterials-ActionRow > div > svg:hover {
    fill: var(--primary-color);
    stroke: var(--primary-color);
}

.ListRequestMaterials-List-Container > div > div > table > tbody {
    overflow-y: scroll;
    /* height: 50vh; */
    height: 400px;
    
}

.ListRequestMaterials-List-Container > div > div > table > tbody > tr {
    border-bottom: 1px solid #e4e4e4;
}


.ListRequestMaterials-Loading-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 50vh; */
    height: 400px;

}