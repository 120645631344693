.MaterialStockInfoModal {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    /* width: 90%; */
    /* height: 700px; */
}

.MaterialStockInfoModal-StockInfo {
    margin-bottom: 1rem;
}

.MaterialStockInfoModal-StockInfo-Header {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.MaterialStockInfoModal-Title {
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
}


.MaterialStockInfoModal-StockInfo-Status {
    display: flex;
    gap: 0.5rem;
}

.MaterialStockInfoModal-Input-W {
    width: 100%;
    grid-column: span 1;
}

.MaterialStockInfoModal-NewSelect-Node {
    padding: 0.5rem;
}

/* .MaterialStockInfoModal-Input-FullRow {
    grid-column: span 4;
}

.MaterialStockInfoModal-Input-2Col {
    grid-column: span 2;
}

.MaterialStockInfoModal-Asterisk {
    color: rgb(236, 106, 106);
} */

.MaterialStockInfoModal-Form-Body {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
}

.MaterialStockInfoModal-Form-Inputs {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.5rem;
}

.MaterialStockInfoModal-Form-Body-ExpirationDate {
    display: grid;
    column-gap: 4px;
    grid-column-gap: 4px;
}

/* Material Batches */
.MaterialStockInfoModal-Batches {
    padding-top: 0.5rem;
}

.MaterialStockInfoModal-Batches-Header {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    padding: 0 2rem;
}

.MaterialStockInfoModal-Batches-Header > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.MaterialStockInfoModal-Batches-Header > div > p {
    margin: 0;
}

.MaterialStockInfoModal-NumberBox {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 0.5rem;
}

.MaterialStockInfoModal-Batches-IconBox {
    height: 1.8rem;
}

.MaterialStockInfoModal-Batches-IconBox > svg {
    fill: #bbb;
    stroke: #bbb;
    cursor: pointer;
    height: 1.8rem;
    width: 1.8rem;
}

.MaterialStockInfoModal-Batches-IconBox:hover > svg {
    fill: var(--primary-color);
    stroke: var(--primary-color);
    transition: 0.3s ease;
}

.MaterialStockInfoModal-Batches-Carrousel-Container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    gap: 0.5rem;
}

.MaterialStockInfoModal-Batches-Carrousel-Arrow {
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #ccc;
    transition: 0.3s;
    padding: 0;
    background-color: #fff;
}

.MaterialStockInfoModal-Batches-Carrousel-Arrow:disabled {
    background-color: #fff !important;
}

.MaterialStockInfoModal-Batches-Carrousel-Arrow:not(:disabled):hover {
    color: #aaa;
}

.MaterialStockInfoModal-Batches-Carrousel-Arrow:disabled {
    cursor: default;
    color: #ddd; /* Deixa a seta mais clara para indicar desativado */
    opacity: 0.5;
    background: none;
}

.MaterialStockInfoModal-Batches-Carrousel-Wrapper{
    width: 100%;
    overflow: hidden;
    height: 390px;
}

.MaterialStockInfoModal-Batches-Carrousel {
    display: flex;
    gap: 1rem;
    transition: transform 0.3s ease-in-out;
    height: 100%;

}

.MaterialStockInfoModal-NoBatches {
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MaterialStockInfoModal-NoBatches > p {
    color: #ccc;
    font-size: 1.325rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.MaterialStockInfoModal-NoBatches-IconBox {
    cursor: pointer;
}

.MaterialStockInfoModal-NoBatches-IconBox > svg {
    fill: #ccc;
    stroke: #ccc;
    width: 3.5rem;
    height: 3.5rem;
}

.MaterialStockInfoModal-NoBatches-IconBox:hover > svg {
    fill: var(--primary-color);
    stroke: var(--primary-color);
    transition: 0.3s ease;
}

.MaterialStockInfoModal-BatchForm-Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.MaterialStockInfoModal-BatchForm-Header > div {
    cursor: pointer;

}

.MaterialStockInfoModal-Loading-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    height:490px;
    padding: 1rem;
}
