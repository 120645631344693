.FilterRequestParams-Select .SelectBox, .FilterRequestParams-Select .form-box, .FilterRequestParams-Input .form-box {
    padding-top: 4px;
	
}

.FilterRequestParams-ActiveContainer {
	display: flex;
    gap: 1.5rem;
}

.FilterRequestParams-ActiveFilters {
	display: flex;
	flex-direction: column;
	margin-top: 0.5rem;
}

.FilterRequestParams-SideBarLeft-ActionRow {
	margin: 0.5rem 0;
    display: flex;
    align-items: center;
	column-gap: 0.5rem;
    height: 20px;
    margin-bottom: 1.5rem;
}

.FilterRequestParams-SideBarLeft-ActionRow > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
	font-weight: bolder;
	color: #bbb;
}

.FilterRequestParams-SideBarLeft-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    height: 20px;
}

.FilterRequestParams-SideBarLeft-ActionRow > div:hover {
	border: 2px solid var(--primary-color);
}

.FilterRequestParams-SideBarLeft-ActionRow > div:hover > svg {
    fill: var(--primary-color);
}

.FilterRequestParams-Title {
	font-weight: bold;
	font-size: 1em;
}

.FilterRequestParams-List {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	gap: 10px;
	padding: 0;
	margin: 0;
}

.FilterRequestParams-Button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	overflow: hidden;
	cursor: pointer;
	padding: 0;
	border-radius: 14px;
}

.FilterRequestParams-Separator {
	width: 1px;
	height: 20px;
	background-color: var(--input-color);
}

.FilterRequestParams-Name{
	margin: 0;
	color: var(--input-color);
	font-size: 0.875rem;
	padding: 0.3rem 1rem;
}

.FilterRequestParams-Remove{
	color: var(--input-color);
	text-transform: uppercase;
	font-size: 1.3em;
	padding: 0.3rem;
	width: 60px;
	max-width: max-content;
}

.FilterRequestParams-Button{
	background-color: #e4e4e4;
	transition: 0.3s ease;
}

.FilterRequestParams-Remove {
	background-color: #ddd;
	transition: 0.3s ease;
	align-items: center;
	display: flex;
}

.FilterRequestParams-Remove > svg {
	fill: #aaa;
	transition: 0.3s ease;

}

.FilterRequestParams-Button:hover{
	background-color: var(--primary-color-light-2);
} 

.FilterRequestParams-Button:hover .FilterRequestParams-Name{
	color: var(--primary-color-dark);
}

.FilterRequestParams-Button:hover .FilterRequestParams-Remove{
	background-color: var(--primary-color);
	color: white;
}

.FilterRequestParams-Button:hover > div > svg {
	fill: #fff;
}

.FilterRequestParams-ActiveContainer-Wrapper {
    height: 6rem;
	display: flex;
	align-items: center;
}