.DeleteEntryBatchMaterialModal {
    width: 352px;
}

.DeleteEntryBatchMaterialModal-Subtitle {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
}

.DeleteEntryBatchMaterialModal-Subtitle > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
	font-weight: bolder;
	color: #bbb;
    color: #bbb;
}

.DeleteEntryBatchMaterialModal-Subtitle > b {
    color: #bbb;
}

.DeleteEntryBatchMaterialModal-Subtitle > div > svg {
    width: 24px;
    fill: #bbb;
    height: 24px;
}

.DeleteEntryBatchMaterialModal-BtnBox {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}