.finance-page {
    display: grid;
    grid-template-columns: 200px auto;
    padding-right: 50px;
}

.finance-page .modal-header-title {
    color: var(--atual-color);
    font-weight: bold;
    border: 0;
}

.finance-add-button {
    padding: 5px 10px 20px 10px;
    display: flex;
    justify-content: flex-end;
}

.procedure-actions {
    display: grid;
    grid-template-columns: 25px 25px;
    column-gap: 8px;
    text-align: center;
}

.procedure-action {
    cursor: pointer;
    height: 25px;
}

.procedure-action:hover {
    font-size: 16px;
}

.procedure-action svg {
    fill: var(--atual-color);
}

.procedure-form {
    --modal-size: 45dvw;
    display: grid;
    grid-template-columns: repeat(2, var(--modal-size));
    width: var(--modal-size);
    max-height: 80dvh;
    overflow: hidden;
    overflow-y: auto;
}

.procedure-content {
    padding: 20px;
    width: 100%;
}

.finance-form-buttons {
    display: flex;
    justify-content: flex-end;
}

.finance-form-buttons .button-container {
    padding-left: 15px;
}

.costs-add-content {
    background-color: white;
    width: 100%;
}

.costs-add-content.open {
    padding: 5px;
}

.procedure-autocomplete {
    position: absolute;
    z-index: 3;
    background-color: white;
    padding: 7px;
    border: 1px solid var(--input-bgh);
    border-radius: 4px;
    width: calc(100% - 70px);
}

.procedure-autocomplete .item {
    line-height: 25px;
    cursor: pointer;
}


.procedure-autocomplete .item:hover {
    background-color: var(--input-bgh);
}

.code-error {
    font-size: 12px;
    color: var(--error-bgh);
    height: 20px;
    position: relative;
    bottom: 10px;
}

.receipt-actions {
    display: grid;
    grid-template-columns: auto auto;
    cursor: pointer;
}


