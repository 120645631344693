.RequestDetailsModal {
    width: 400px;
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;
}

.RequestDetailsModal-Content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.RequestDetailsModal-Content > div > span {
    font-weight: 600;
}

.RequestDetailsModal-Status-Container{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.5rem;
}
.RequestDetailsModal-Status-Container > span {
    font-weight: 600;
}

.RequestDetailsModal-Justification {
    height: 90px;
    overflow-y: auto;
    margin-left: 0.5rem;
    word-wrap: break-word;
    margin-top: 0.2rem;
}

/* ScrollBar Style */
.RequestDetailsModal-Justification::-webkit-scrollbar {
    width: 6px; 
    height: 6px; 
}

.RequestDetailsModal-Justification::-webkit-scrollbar-track {
    background: transparent; 
}

.RequestDetailsModal-Justification::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px; 
}

.RequestDetailsModal-Justification::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.RequestDetailsModal-Status-Subtitle {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color-light-2);
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    width: min-content;
}

.RequestDetailsModal-Success {
    border: 1px solid #14A44D;
    color:#14A44D;
    background-color: #e8fcf0;
}

.RequestDetailsModal-Error {
    border: 1px solid #f95c5c;
    color:#f95c5c;
    background-color: #fee6e6;
}