.receipt-form-container {
    max-height: 80vh;
    overflow-y: auto;
    padding: 20px;
}

.receipt-form {
    min-width: 500px;
    max-width: 600px;
}

.receipt-grid {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
}

.receipt-grid.payer {
    grid-template-columns: 60% auto;
}

.receipt-item-modal {
    min-width: 400px;
}

.receipt-item-values {
    display: grid;
    grid-template-columns: auto auto;
}

.button-group {
    display: flex;
    justify-content: flex-end;
}

.button-group * {
    padding: 10px;
}

.receipt-select-anchor {
    position: relative;
    z-index: 2;
}

.receipt-select {
    display: grid;
    grid-template-columns: auto 70px;
}

.receipt-select .search {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    margin: 13px;
    margin-left: 4px;
    position: relative;
    bottom: 2px;
    font-size: 20px;
}

.receipt-select .search * {
    fill: var(--primary-color);
}

.receipt-select .search:hover {
    cursor: pointer;
    background-color: var(--primary-color);
}

.receipt-select .search:active {
    border-top-color: white;
    border-right-color: white;
    font-size: 19px;
}

.receipt-select .search:hover * {
    fill: white;
}

.installment-toggle .toggle {
    height: unset;
}

.installment-date {
    height: 30px;
    position: relative;
}

.installment-date input {
    position: absolute;
    width: 140px;
    top: -4px;
    min-height: 20px;
}