/* Next appointment info */
.NextAppointment-Info-Chart {
    height: 330px;
    width: 100%;
    padding: 1.25rem;
    border: 1px solid #eee;
    border-radius: 0.5rem;
    box-shadow: 1px -1px 10px 1px rgba(80, 80, 80, 0.1);
}

.NextAppointment-Info-Chart-Header{
    position: relative;
    display: flex;
    justify-content: space-between;
}

.NextAppointment-Info-Chart-Title {
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
}

.NextAppointment-Actions {
    width: 50%;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: right;
}

.NextAppointment-OpenAttendance{
    display: flex;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #bbb;
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
    font-size: 1.5rem;
    /* position: absolute;
    top: 0.25rem;
    right: 0.25rem; */
    cursor: pointer;
    width: 2.5rem;

}

.NextAppointment-OpenAttendance:hover {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.NextAppointmet-Cursor-Container{
    font-size: 1.25rem;
    color: #aaa;
    display: flex;
    gap: 0.25rem;
}

.NextAppointmet-Cursor-Container > div:hover {
    color: #888;
}

.NextAppointment-OpenAttendance > p {
    margin: 0;
}

.NextAppointment-Sections-Container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    position: relative;

}

.NextAppointment-Status {
    padding: 0.25rem 0.5rem;
    align-items: center;
    position: absolute;
    left: 0.25rem;
    top: -1.5rem;
    border-radius: 0.5rem;
    font-weight: 600;
}

.NextAppointment-Section {
    padding: 0.5rem;
    width: 50%;
}

.NextAppointment-Section-Title {
    font-size: 1rem;
    font-weight: 600;
}

.NextAppointment-Section-Patient {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.NextAppointment-Section-Patient-Name {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
}

.NextAppointment-Section-Patient-SubInfo {
    display: flex;
    color: #aaa;
    width: 100%;
}

.NextAppointment-Section-Patient-SubInfo>p {
    width: 50%;
}

.NextAppointment-Section-Patient-SubInfo>p:first-of-type {
    border-right: 1px solid #aaa;
    text-align: right;
    padding-right: 0.5rem;
    margin-right: 0.5rem;
}

.NextAppointment-Section-Attendance-Container {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 1rem;
}

.NextAppointment-Section-Attendance{
    font-size: 1rem;
}

.NextAppointment-Section-Attendance > p:last-of-type {
    margin: 0;
}

.NextAppointment-Section-Attendance span {
font-weight: 500;
}

.NextAppointment-Section-Attendance-Time {
    display: flex;
    gap: 5px;
}

.NoNextAppointments-Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.NoNextAppointments-Message {
    font-size: 1.125rem;
    font-weight: 600;
    color: #aaa;
    text-align: center;
}