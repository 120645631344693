.MaterialAssignment {
    /* padding: 0.5rem 1rem; */
    position: relative;
    height: 100%;
}

.MaterialAssignment-Content {
    height: calc(100% - 44px - 16px);
}

.MaterialAssignment-Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
}

.MaterialAssignment-Title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #ccc;
    margin: 0;
}

.MaterialAssignment-Actions {
    display: flex;
    gap: 0.5rem;
}

.MaterialAssignment-SubmitBtn > svg {
    width: 2rem;
    height: 2rem;
    fill: #ccc;
    cursor: pointer;
    
}

.MaterialAssignment-SubmitBtn:hover > svg{
    transition: 0.3s ease;
    fill: var(--primary-color);
}

.MaterialAssignment-CloseBtn {
    fill: #ccc;
    cursor: pointer;

}

.MaterialAssignment-CloseBtn:hover > svg {
    transition: 0.3s ease;
    fill: #aaa;
}

.MaterialAssignment-Form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
}

.MaterialAssignment-Input > #box-requested_amount {
    margin: 0;
}

.MaterialAssignment-Input > #box-requested_amount > span {
    padding:  0.2rem 0.2rem 0 0.2rem ;
}

.MaterialAssignment-Asterisk {
    color: rgb(236, 106, 106);
}

.MaterialAssignment-Italic {
    color: #777;
    font-size: 0.8rem;
}

.MaterialAssignment-Footer {
    display: flex;
    justify-content: right;
    z-index: 2;
}