.AvailableScheduleMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* flex-wrap: wrap; */
    column-gap: 1rem;
    font-size: 14px;
    width: 100%;
    cursor: default;
}

.AvailableScheduleMenu-TitleDiv {
    display: flex;
    align-items: self-start;
    justify-content: space-between;
}

.AvailableScheduleMenu-Title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AvailableScheduleMenu-checkbox[type=checkbox] {
    appearance: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 12px;
    height: 12px;
    background-color: #d4edda;
    border: 1px solid #4a934a;
    border-radius: 0.2em;
    transition: .2s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AvailableScheduleMenu-checkbox[type=checkbox]:checked::before {
    content: "\2714";
    position: absolute;
    color: #fff;
}

.AvailableScheduleMenu-checkbox[type=checkbox]:checked {
    background-color: var(--primary-bg);
    border-color: var(--primary-bg);
}

.AvailableScheduleMenu-HourTime {
    display: flex;
    column-gap: 0.25rem;
    align-items: center;
}

.AvailableScheduleMenu-Dropdown {
    position: relative;
    display: inline-block;
}

.AvailableScheduleMenu-Dropdown-content {
    display: flex;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1 !important;
    white-space: nowrap;
    border-radius: 8px;

}

.AvailableScheduleMenu-Dropdown-item:first-of-type {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.AvailableScheduleMenu-Dropdown-item:last-of-type {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.AvailableScheduleMenu-Dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
    fill: #aaa;
}

.AvailableScheduleMenu-Dropdown-item:hover {
    background-color: #f1f1f1;
    fill: var(--primary-color);
}