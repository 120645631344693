.LogsAppointmentsBookSection-Header {
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.LogsAppointmentsBookSection-Header-Tabs {
	display: grid;
	grid-template-columns: 160px 160px;
}

.LogsAppointmentsBookSection-Header-Tabs-Btn {
	width: 100%;
	display: flex;
	font-size: 0.8rem;
	cursor: pointer;
	color: rgb(28, 30, 33);
	height: 40px;
	align-items: center;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	color: #333333;
}

.LogsAppointmentsBookSection-Header-Tabs-Btn:hover {
	border-bottom: 1px solid var(--primary-color);
}

.LogsAppointmentsBookSection-Header-Tabs-Btn.active {
	color: var(--primary-color);
	fill: var(--primary-color);
	border-bottom: 1px solid var(--primary-color);
}

.LogsAppointmentsBookSection-Header-Filters {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-template-columns: 184px 108px min-content 108px;
    column-gap: 0.5rem;
    margin-bottom: 0.25rem;
}

.LogsAppointmentsBookSection-Header-Filters .SelectBox, .LogsAppointmentsBookSection-Header-Filters .form-box {
    padding: 0;
    margin: 0;
}

.LogsAppointmentsBookSection-Body {
    margin-top: 1rem;
}