.Attendance-Observations-Container {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 300px;
    padding: 1rem;

}

.Attendance-Observations-Container > h1 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.Attendance-Observations-Container > div {
    flex-wrap: wrap;
    width: 100%;
    word-wrap: break-word;
    line-height: 1.2rem;
}