.WaitingRoom-Loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
}

.WaitingRoom-List {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - 148px);
}

.WaitingRoom-Content {
	display: grid;
	grid-template-columns: 300px min-content 1fr;
	column-gap: 1rem;
	flex-grow: 1;
}

.WaitingRoom-Separator {
	width: 1px;
	min-height: 45vh;
	background-color: #eee;
}

.WaitingRoom-Body {
	width: 100%;
}

.WaitingRoom-Attendance-Content {
	height: calc(100vh  - 250px);

}

.WaitingRoom-AttendanceUnitWrapper {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding-bottom: 1rem;
	overflow-y: scroll;
	height: 100%;

}

.WaitingRoom-NotFound {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	fill: #aaa;
	color: #aaa;
	height: calc(100vh - 240px);
}

.WaitingRoom-NotFound > div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	row-gap: 1rem;
	padding: 3rem;
}

.WaitingRoom-NotFound svg {
	width: 52px;
	height: 52px;
}

.WaitingRoom-NotFound p {
	font-size: 1rem;
}
