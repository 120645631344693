.ListEntryBatchMaterials {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    margin-top: 1rem;
}

.ListEntryBatchMaterials-NewSelect {
    padding: 0.5rem;
}

.ListEntryBatchMaterials-Filters-Body {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1rem;
    flex-wrap: wrap;
    max-width: 1224px;
}

.ListEntryBatchMaterials-Status-Subtitle {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color-light-2);
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    width: min-content;
}

.ListEntryBatchMaterials-Success {
    border: 1px solid #14A44D;
    color:#14A44D;
    background-color: #e8fcf0;
}

.ListEntryBatchMaterials-Error {
    border: 1px solid #f95c5c;
    color:#f95c5c;
    background-color: #fee6e6;
}

.ListEntryBatchMaterials-HeaderRow-Action {
    text-align: right;
}

.ListEntryBatchMaterials-ActionRow {
    display: flex;
    align-items: center;
    height: 20px;
    justify-content: center;
}

.ListEntryBatchMaterials-ActionRow > div > svg {
    width: 12px;
    fill: #bbb;
    cursor: pointer;
    height: 12px;
}

.ListEntryBatchMaterials-ActionRow > div > svg:hover {
    fill: var(--primary-color);
}

.ListEntryBatchMaterials-ActionRow > div.action-disabled  {
    cursor: not-allowed;
}

.ListEntryBatchMaterials-ActionRow > div.action-disabled > svg {
    cursor: not-allowed;   
    fill: #ddd;
}

.ListEntryBatchMaterials-ActionRow > div.action-disabled > svg:hover {
    fill: #ddd;
}


.ListEntryBatchMaterials-List-Container > div > div > table > tbody {
    overflow-y: scroll;
    /* height: 50vh -40px; */
    height: 400px;
}

.ListEntryBatchMaterials-List-Container > div > div > table > tbody > tr {
    border-bottom: 1px solid #e4e4e4;
}


.ListEntryBatchMaterials-Loading-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 50vh; */
    height: 400px;

}