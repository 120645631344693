.pagination-custom {
  color: var(--input-color);
  text-align: center;
  margin-top: 2rem;
}
.pagination-custom > span.pagination-info {
  text-align: center;
  display: block;
  font-size: 80%;
  margin-top: 2px;
}
.pagination-custom > div {
  display: inline-flex;
}
.pagination-custom > button {
  margin: 0 10px;
}

.pagination-custom .page-item input {
  background-color: transparent;
  border: 1px solid var(--input-bgh);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  color: currentColor;
  font-size: 1rem;
  padding: 7px;
  text-align: center;
  width: 90px;
}
.pagination-custom .page-item button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
